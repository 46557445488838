// TODO(Indrek): Simplify to string & inline if possible
type EmailType = string | null

export const useCriteo = () => {
  const {
    public: { criteoPartnerId },
  } = useRuntimeConfig()

  const productImpressions = (email: EmailType, products: number[]) => {
    if (import.meta.client) {
      initialize()

      window.criteo_q.push(...common(email), { event: 'viewList', item: products.map((product) => product.toString()) })
    }
  }

  const productImpression = (email: EmailType, productId: number) => {
    if (import.meta.client) {
      initialize()

      window.criteo_q.push(...common(email), { event: 'viewItem', item: productId.toString() })
    }
  }

  const home = (email: EmailType) => {
    if (import.meta.client) {
      initialize()

      window.criteo_q.push(...common(email), { event: 'viewHome' })
    }
  }

  const conversion = (email: EmailType, transactionId: string, products: {productId: string, price: string, quantity: string}[]) => {
    if (import.meta.client) {
      initialize()

      window.criteo_q.push(
        ...common(email),
        {
          event: "trackTransaction",
          id: transactionId,
          item: products.map((product) => ({ id: product.productId, price: product.price, quantity: product.quantity }))
        }
      )
    }
  }

  const initialize = () => {
    window.criteo_q = window.criteo_q || []
  }

  const common = (email: EmailType) => {
    return [
      { event: 'setAccount', account: criteoPartnerId },
      { event: 'setEmail', email: email ?? '', hash_method: 'md5' },
      { event: 'setSiteType', type: getDeviceType() },
    ]
  }

  const getDeviceType = () => {
    return /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd'
  }

  return {
    productImpressions,
    productImpression,
    home,
    conversion,
  }
}
